<template>
    <div v-if="isModalLoaded" class="v-modal modal-cropper">
        <div class="modal-header">
            <h4 class="modal-title">Выберите область</h4>
        </div>
        <vue-cropper
            ref="cropper"
            alt="Source Image"
            :src="image"
            :viewMode="1"
            dragMode="move"
            :aspectRatio="1"
            :minCropBoxWidth="100"
            :minCropBoxHeight="100"
        >
        </vue-cropper>
        <div class="modal-footer">
            <button
                type="button"
                @click="onCancel"
                class="btn btn-rounded btn-alt-secondary"
            >Отмена</button>
            <button
                type="button"
                @click="onSave"
                class="btn btn-rounded btn-primary-dark"
            >Сохранить</button>
        </div>
    </div>
</template>

<script>
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';

    export default {
        name: "CropperModal",
        components: {VueCropper},
        props: {
            image: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                modalName: 'CropperModal',
                isModalLoaded: false,
                data: '',
                preview: ''
            }
        },
        mounted() {
            this.isModalLoaded = true;
        },
        methods: {
            onCancel() {
                this.$modal.hide(this.modalName);
            },
            onSave() {
                const canvas = this.$refs.cropper.getCroppedCanvas();
                this.preview = canvas.toDataURL("image/png");
                this.data = this.$refs.cropper.getData();
                this.$parent.$emit('setPreview', this.preview);
                this.$parent.$emit('onSave', this.data);
                this.$modal.hide(this.modalName);
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .cropper-view-box,
    /deep/ .cropper-face {
        border-radius: 50%;
    }
</style>
